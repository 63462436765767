import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";

const Danke = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="Danke"
      description="Danke für deine Interesse am Verein. Wir haben deine Nachricht erhalten und werden uns umgehend bei dir melden."
    />
    <div>
      <h1>Danke :)</h1>

      <p>
        Danke für deine Interesse am Verein. Wir haben deine Nachricht erhalten
        und werden uns umgehend bei dir melden.
      </p>
      <p>
        Zurück zur <Link to="/">Homepage</Link>.
      </p>
    </div>
  </Layout>
);

export default Danke;
